import { Permissions } from '@webapp/models/enums'
import { useCheckPermission } from '../useCheckPermission'
import { useFeatureFlag } from '../useFeatureFlag'

const AP_BILL_PERMISSIONS_HIERARCHY = {
  [Permissions.ACCOUNTS_PAYABLE_VIEW_BILL]: 0,
  [Permissions.ACCOUNTS_PAYABLE_EDIT_DRAFT_PENDING_DENIED_BILLS]: 1,
  [Permissions.ACCOUNTS_PAYABLE_EDIT_APPROVED_BILLS]: 2,
  [Permissions.ACCOUNTS_PAYABLE_DELETE_SUBMITTED_BILLS]: 3,
}

/**
 * Determines the highest permission level for bills based on the user's current permissions.
 *
 * This function retrieves the user's permissions, compares them against predefined permission levels,
 * and returns the highest level of permission that the user possesses.
 *
 * @returns {number} The highest permission level, with higher numbers indicating more elevated permissions.
 *                    Returns `-1` if the user has no bills permissions.
 */
const getHighestBillPermissionLevel = (): number => {
  const permissions = Object.keys(
    AP_BILL_PERMISSIONS_HIERARCHY
  ) as Permissions[]
  const levels = permissions.map((permission) =>
    useCheckPermission(permission)
      ? AP_BILL_PERMISSIONS_HIERARCHY[permission]
      : -1
  )
  return Math.max(...levels)
}

/**
 * Checks if the user is in "view-only" mode for bills.
 *
 * @returns {boolean} True if the user can only view bills without elevated permissions.
 */
const isViewOnly = (highestBillPermissionLevel: number): boolean => {
  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )

  return (
    CL_1058_AP_GRANULAR_PERMISSIONS &&
    highestBillPermissionLevel ===
      AP_BILL_PERMISSIONS_HIERARCHY[Permissions.ACCOUNTS_PAYABLE_VIEW_BILL]
  )
}

// Check if the user has at least a certain permission level
const hasBillPermissionLevel = (
  requiredPermission: Permissions,
  highestBillPermissionLevel: number
) => {
  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )
  return (
    CL_1058_AP_GRANULAR_PERMISSIONS &&
    highestBillPermissionLevel >=
      AP_BILL_PERMISSIONS_HIERARCHY[requiredPermission]
  )
}

export const useBillsPermissions = () => {
  const highestBillPermissionLevel = getHighestBillPermissionLevel()

  return {
    canViewOnlyBills: isViewOnly(highestBillPermissionLevel),
    canViewBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_VIEW_BILL,
      highestBillPermissionLevel
    ),
    canEditPendingDraftDeniedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_EDIT_DRAFT_PENDING_DENIED_BILLS,
      highestBillPermissionLevel
    ),
    canEditApprovedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_EDIT_APPROVED_BILLS,
      highestBillPermissionLevel
    ),
    canDeleteSubmittedBills: hasBillPermissionLevel(
      Permissions.ACCOUNTS_PAYABLE_DELETE_SUBMITTED_BILLS,
      highestBillPermissionLevel
    ),
  }
}
